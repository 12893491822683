<template>
  <div class="content-wrapper">
    <div class="customers main-content">

      <div class="page-header">
        <h1>Potential Customers</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Customer Name" @search="searchCustomer" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Company">
                <el-input v-model="searchForm.company"></el-input>
              </el-form-item>
              <el-form-item label="Email Address">
                <el-input v-model="searchForm.email_address"></el-input>
              </el-form-item>
              <el-form-item label="Phone Number">
                <el-input v-model="searchForm.phone_number"></el-input>
              </el-form-item>
              <el-form-item label="Type">
                <el-input v-model="searchForm.type"></el-input>
              </el-form-item>
              <el-form-item label="Principal Employee">
                <el-input v-model="searchForm.employee_name"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
        <div class="tool">
          <router-link :to="{name:'AddPotentialCustomer'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="customer-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Company</th>
              <th scope="col">Email Address</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Type</th>
              <th scope="col">Principal Employee</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers">
              <td>{{customer.name}}</td>
              <td>{{customer.company}}</td>
              <td>{{customer.email_address}}</td>
              <td>{{customer.phone_number}}</td>
              <td>{{customer.type}}</td>
              <td>{{customer.employee_name}}</td>
              <td class="action">
                <router-link :to="{name:'PotentialCustomer', params: {id: customer.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deleteCustomer(customer.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// @ is an alias to /src
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'Customers',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        company: '',
        email_address: '',
        department: '',
        phone_number: '',
        type: '',
        employee_name: '',
      },
      originCustomers: [],
      customers: [],
    }
  },
  mounted(){
    this.loadCustomers();
  },
  methods:{
    async loadCustomers(){
      const loginInfo = Common.getLoginInfo();
      try{
        const customers = await Sales.loadAllPotentialCustomers(this.apiUrl, loginInfo);
        this.originCustomers = customers;
        this.customers = customers;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.customers = this.originCustomers;
    },
    searchCustomer(textSearchValue){
      this.searchForm['name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newCustomers = Common.filterItems(searchFilter, this.originCustomers);
      this.customers = newCustomers;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deletePotentialCustomer(customerId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Sales.deletePotentialCustomer(this.apiUrl, customerId, loginInfo);
        const newCustomers = this.customers.filter(function(item) {
          return item.id !== customerId;
        });
        this.customers = newCustomers;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.customer-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
